import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchStepRegistration() {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/users/steps-registration')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
          /*
          .then(response => resolve([
            200,
            {
              users: response.data.data,
              total: response.data.meta.total,
            },
          ]))
          */
      })
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/users', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
          /*
          .then(response => resolve([
            200,
            {
              users: response.data.data,
              total: response.data.meta.total,
            },
          ]))
          */
      })
    },
    fetchPlans() {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/plans')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/users/${id}`)
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, { id, payload }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/users/${id}`, payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/user/users', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
