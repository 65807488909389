import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Nome', sortable: true },
    { key: 'email', label: 'Email', sortable: true },
    { key: 'ads', label: 'Cidade/UF', sortable: true },
    { key: 'mobile_phone', label: 'Celular', sortable: true },
    { key: 'status_label', label: 'Status', sortable: true },
    { key: 'step_registration_label', label: 'Registro', sortable: true },
    { key: 'actions', label: 'Ações' },
  ]
  const perPage = ref(25)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const stepFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, roleFilter, planFilter, statusFilter, stepFilter], () => {
    refetchData()
  })
  watch([searchQuery], () => {
    if (searchQuery.value.length >= 2) {
      refetchData()
    } else if (searchQuery.value.length === 0) {
      refetchData()
    }
  })

  const fetchUsers = (ctx, callback) => {
    /*
      q: searchQuery.value,
      perPage: perPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      role: roleFilter.value,
      plan: planFilter.value,
   */
    store
      .dispatch('app-user/fetchUsers', {
        page: currentPage.value,
        status: statusFilter.value,
        search: searchQuery.value,
        step_registration: stepFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data
        callback(data)
        totalUsers.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchStepRegistration = (ctx, callback) => {
    store
      .dispatch('app-user/fetchStepRegistration')
      .then(response => {
        callback(response.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Step Registration list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }
  /* eslint-disable */
  const resolveUserStatusVariant = status_label => {
    if (status_label === 'Suspenso') return 'warning'
    if (status_label === 'Inativo') return 'warning'
    if (status_label === 'Bloqueado') return 'warning'
    if (status_label === 'Ativo') return 'success'
    if (status_label === 'Em Progresso') return 'secondary'
    return 'primary'
  }
  /* eslint-enable */

  return {
    fetchUsers,
    fetchStepRegistration,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    stepFilter,
  }
}
